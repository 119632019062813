<template>
  <div class="th-list-course">
    <h2 class="th-title"><b> Data Center Dashboard</b></h2>
    <div class="row px-3 pt-3 justify-content-between" v-if="false">
      <div class="card col-xl-3 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Active Assets</p>
              <p class="text-gray-5 mb-0">Aset dengan garansi aktif</p>
            </div>
            <p class="text-success font-weight-bold font-size-24 mb-0">251</p>
          </div>
        </div>
      </div>
      <div class="card col-xl-4 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Expiring Assets</p>
              <p class="text-gray-5 mb-0">Garansi akan berakhir dalam 3 bulan</p>
            </div>
            <p class="text-warning font-weight-bold font-size-24 mb-0">24</p>
          </div>
        </div>
      </div>
      <div class="card col-xl-4 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Expired Assets</p>
              <p class="text-gray-5 mb-0">Aset dengan garansi berakhir</p>
            </div>
            <p class="text-danger font-weight-bold font-size-24 mb-0">10</p>
          </div>
        </div>
      </div>
    </div>
    <a-card>
      <div class="mb-3 row">
        <div class="col-2 m-1">
          <a-input-search placeholder="Cari Nama Aset..." @input="handleSearch" v-model:value="search" />
        </div>
        <div class="col-2 m-1">
          <a-select v-model:value="selectedFilterLokasi" style="width: 220px">
            <a-select-option v-for="(item, i) in filterLokasi" :key="i" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-2 m-1 ml-5">
          <a-select v-model:value="selectedFilterRuang" style="width: 220px"
            :disabled="filterRuang.length ? false : true">
            <a-select-option v-for="(item, i) in filterRuang" :key="i" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col m-1 w-100 d-flex justify-content-end align-items-center">
          <!-- <router-link :to="`${currentRoute.currentRoutes}`">
            <a-button>
              <PlusOutlined />New {{ currentRoute.strRouteCapital }}
            </a-button>
          </router-link> -->
          <router-link :to="{ path: `${currentRoute.currentRoutes}`, query: { subCategory: `${assetSubCategoryId}` } }">
            <a-button>
              <PlusOutlined /> New {{ currentRoute.strRouteCapital }}
            </a-button>
          </router-link>
        </div>
      </div>
      <div class="w-100" style="overflow-x:auto ;">
        <a-table :pagination="globalPagination"
          :columns="$route.path.split('/').at(1) !== 'infrastruktur' ? columns : columnsInfra"
          :data-source="filteredData" class="table" bordered table-layout="auto" :loading="loadingTable"
          @change="handleTableChange" :row-key="record => record.id">
          <template #name="{ text, record }">
            <router-link v-if="$route.path.split('/').at(1) !== 'infrastruktur'" class="th-link" :to="{
      path: `detail/${record.id}`,
      // params: { id: record.id },
    }">
              {{ text }}
            </router-link>
            <p v-else>
              {{ text }}
            </p>
          </template>
          <template #AssetSubCategory="{ text }">
            <div>
              {{ text ? text.name : '-' }}
              <br />
              <span class="text-muted">
                {{ text ? text.AssetCategory.name : '-' }}
              </span>
            </div>
          </template>
          <template #Manufacturer="{ text }">
            {{ text ? text.name : '-' }}
            <br />
            <span class="text-muted"># {{ text ? text.tag : '-' }}</span>
          </template>
          <template #Customer="{ text }">
            {{ text ? text.name : '-' }}
            <br />
            <span class="text-muted">
              {{ text ? text.officeNumber : '-' }}
              <br />
              {{ text ? text.email : '-' }}
            </span>
          </template>
          <template #Location="{ text, record }">
            {{ text ? text.name : '-' }}
            <br />
            <span class="text-muted">
              {{ record.Room ? record.Room.name : '-' }}
            </span>
          </template>
          <template #aksi="{ record }">
            <a-popconfirm title="Apakah anda yakin akan mengaktifkan asset ini?" ok-text="Ya" cancel-text="Tidak"
              @confirm="approveAsset(record.id)" v-if="record.status == 'Pending'">
              <a-button>Aktifkan</a-button>
            </a-popconfirm>
            <a-tag v-else color="green">Aktif</a-tag>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  onMounted,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  computed,
  onBeforeMount,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  SearchOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
  CloseSquareOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'

import fuzzysort from 'fuzzysort'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import assetsFile from '@/data/modc_dump.json'
import { randomUUID } from '@/helpers/randomUUID'
import { getAssets, editStatusAssetsById } from '../../services/axios/modc/assets'
import { getLocations, getRooms } from '../../services/axios/modc/customer'

const columns = [
  {
    title: 'NAMA ASSET',
    dataIndex: 'name',
    className: '',
    key: 'name',
    slots: { customRender: 'name' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'TIPE ASSET',
    dataIndex: 'AssetSubCategory',
    className: '',
    key: 'AssetSubCategory',
    slots: { customRender: 'AssetSubCategory' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'BRAND',
    dataIndex: 'Manufacturer',
    className: '',
    key: 'Manufacturer',
    slots: { customRender: 'Manufacturer' },
    // width: 100,
    // sorter: true,
  },
  // {
  //   title: 'TANGGAL',
  //   dataIndex: 'tanggal',
  //   className: '',
  //   key: 'tanggal',
  //   slots: { customRender: 'tanggal' },
  //   // width: 100,
  //   // sorter: true,
  // },
  {
    title: 'SERIAL NO',
    dataIndex: 'serialNumber',
    className: '',
    key: 'serialNumber',
    slots: { customRender: 'serialNumber' },
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'Customer',
    className: '',
    key: 'Customer',
    slots: { customRender: 'Customer' },
  },
  {
    title: 'LOCATION',
    dataIndex: 'Location',
    className: '',
    key: 'Location',
    slots: { customRender: 'Location' },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    className: '',
    key: 'status',
    slots: { customRender: 'status' },
    filters: [
      {
        text: 'Active',
        value: 'Active',
      },
      {
        text: 'Pending',
        value: 'Pending',
      },
    ],
    // filters: ['Reparation', 'Part Placement', 'Reconfiguration'],
    onFilter: (value, record) => {
      return record.status == value
    },
  },
  {
    title: 'AKSI',
    dataIndex: 'aksi',
    className: '',
    key: 'aksi',
    slots: { customRender: 'aksi' },
    // sorter: true,
  },
  // {
  //   title: 'NILAI ASET',
  //   dataIndex: '',
  //   className: '',
  //   key: 'assetValue',
  //   slots: { customRender: 'assetValue' },
  //   sorter: true,
  // },
  // {
  //   title: 'AKSI',
  //   dataIndex: 'action',
  //   className: 'd-table-cell',
  //   key: 'action',
  //   width: 100,
  //   slots: { customRender: 'action' },
  //   // sorter: true,
  // },
]
const columnsInfra = [
  {
    title: 'NAMA ASSET',
    dataIndex: 'name',
    className: '',
    key: 'name',
    slots: { customRender: 'name' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'TIPE ASSET',
    dataIndex: 'AssetSubCategory',
    className: '',
    key: 'AssetSubCategory',
    slots: { customRender: 'AssetSubCategory' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'CUSTOMER',
    dataIndex: 'Customer',
    className: '',
    key: 'Customer',
    slots: { customRender: 'Customer' },
  },
  {
    title: 'LOCATION',
    dataIndex: 'Location',
    className: '',
    key: 'Location',
    slots: { customRender: 'Location' },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    className: '',
    key: 'status',
    slots: { customRender: 'status' },
    filters: [
      {
        text: 'Active',
        value: 'Active',
      },
      {
        text: 'Pending',
        value: 'Pending',
      },
    ],
    // filters: ['Reparation', 'Part Placement', 'Reconfiguration'],
    onFilter: (value, record) => {
      return record.status == value
    },
  },
  {
    title: 'AKSI',
    dataIndex: 'aksi',
    className: '',
    key: 'aksi',
    slots: { customRender: 'aksi' },
    // sorter: true,
  },
]

export default {
  components: {
    CloudUploadOutlined,
    CloudDownloadOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    ToolOutlined,
    PhoneOutlined,
    HomeOutlined,
    PlusOutlined,
  },
  setup() {
    /* eslint-disable */
    let store = useStore()
    const route = useRoute()
    let filteredData = ref([])
    let isSearching = ref(false)
    let loadingTable = ref(false)
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let totalPages = ref([])

    let search = ref('')
    const currentRoute = computed(() => {
      const currentRoute = route.path.split('/').at(1)
      let strRouteCapital = currentRoute.split('')[0].toUpperCase() + currentRoute.slice(1)

      let typeAssets = ''
      switch (route.path.split('/').at(1)) {
        case 'facilities':
          typeAssets = 'Facility '
          break
        case 'passive':
          typeAssets = 'Passive '
          break
        case 'active':
          typeAssets = 'Active '
          break

        default:
          break
      }
      console.log(route.path.split('/').at(1) + '/create')
      console.log(typeAssets)
      return {
        strRouteCapital,
        currentRoutes: 'create',
      }
    })

    //

    let filterLokasi = ref([])
    let selectedFilterLokasi = ref('Filter Lokasi')
    let selectedFilterCustomer = ref('Filter Customer')
    let filterRuang = ref([])
    let selectedFilterRuang = ref('Filter Ruang')
    let assetSubCategoryId = ref('')
    let typeAsset = ref('')
    const onSearch = async searchText => {
      if (searchText !== '') {
        isSearching.value = true
        loadingTable.value = true
        const res = await searchPotensi(searchText)
        console.log(res, 'res search')
        // const results = fuzzysort.go(searchText, potensiData.value, { key: 'name' })
        // const sortData = results.map(result => {
        //     return result.obj
        // })
        filteredData.value = res.result
        loadingTable.value = false
      } else {
        isSearching.value = false
        loadingTable.value = false
        fetchCourse(1)
      }
    }
    onBeforeMount(() => {
      fetchCourse()
    })
    // onMounted(() => {
    //   fetchCourse()
    // })
    const fetchCourse = async (search = '') => {
      loadingTable.value = true
      let assetSubCategory

      switch (route.path.split('/').at(-1)) {
        case 'access_door':
          assetSubCategory = 19
          break
        case 'cctv':
          assetSubCategory = 18
          break
        case 'precision_ac':
          assetSubCategory = 13
          break
        case 'standard_ac':
          assetSubCategory = 14
          break
        case 'electrical_panel':
          assetSubCategory = 7
          break
        case 'env_monitoring':
          assetSubCategory = 20
          break
        case 'fire_suppresion':
          assetSubCategory = 15
          break
        case 'fire_piping':
          assetSubCategory = 16
          break
        case 'fire_extinguisher':
          assetSubCategory = 17
          break
        case 'generator_set':
          assetSubCategory = 10
          break
        case 'ups':
          assetSubCategory = 8
          break
        case 'battery':
          assetSubCategory = 11
          break
        case 'access_point':
          assetSubCategory = 22
          break
        case 'firewall':
          assetSubCategory = 23
          break
        case 'router':
          assetSubCategory = 24
          break
        case 'server':
          assetSubCategory = 25
          break
        case 'switch':
          assetSubCategory = 26
          break
        case 'storage':
          assetSubCategory = 32
          break
        case 'utp_cable':
          assetSubCategory = 27
          break
        case 'utp_patch_panel':
          assetSubCategory = 28
          break
        case 'fo_patch_panel':
          assetSubCategory = 29
          break
        case 'fo_cable':
          assetSubCategory = 30
          break
        case 'rack':
          assetSubCategory = 3
          break
        case 'list':
          assetSubCategory = 31
          break
        default:
          break
      }
      console.log(route.path.split('/').at(-1), '<<<ROUTE', assetSubCategory, '<<<ASSET')
      assetSubCategoryId.value = assetSubCategory
      typeAsset.value = route.path.split('/').at(-1)
      const data = await getAssets({
        type:
          route.path.split('/').at(1) != 'infrastruktur'
            ? assetSubCategory
            : encodeURIComponent(JSON.stringify([1, 2, 4, 9, 12, 5, 6])),
      }, '', selectedFilterLokasi.value, selectedFilterRuang.value, search)
      // console.log(data, '<<<<<DATA')
      filteredData.value = data.assets
      const { locations } = await getLocations()
      filterLokasi.value = locations.map(e => ({ name: e.name, id: e.id }))

      loadingTable.value = false
      isSearching.value = false
      globalPagination.value = { ...globalPagination.value, total: data.assets.length }
    }

    const lodashSearch = lodash.debounce(() => fetchCourse(), 1500)
    const handleSearch = () => {
      globalPagination.value.current = 1
      lodashSearch()
      // fetchCourse({ search: search.value })
    }
    const handleTableChange = (pag, filters, sorter) => {
      globalPagination.value.current = pag.current
      // if (sorter) {
      //   if (sorter.columnKey == 'key') sortBy.value = '_id'
      //   else sortBy.value = sorter.columnKey
      //   order.value = sorter.order
      // }
      fetchCourse()
    }
    const fetchRooms = async (id = '') => {
      const { rooms } = await getRooms('', id)
      filterRuang.value = rooms
    }
    watch([route, selectedFilterLokasi, selectedFilterRuang, search], (to, from) => {
      console.log(selectedFilterLokasi.value)
      fetchRooms(selectedFilterLokasi.value)
      fetchCourse(search.value)
    })
    const approveAsset = async id => {
      const res = await editStatusAssetsById(id)
      console.log(res, '<<<<<')
      if (res) {
        notification.success({
          message: 'Berhasil Diaktifkan',
          description: 'Data Asset Berhasil Diaktifkan',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      await fetchCourse()
    }

    return {
      handleSearch,
      handleTableChange,
      approveAsset,
      currentRoute,
      search,
      filterLokasi,
      selectedFilterLokasi,
      selectedFilterCustomer,
      filterRuang,
      selectedFilterRuang,
      globalPagination,
      filteredData,
      loadingTable,
      columns,
      columnsInfra,
      assetSubCategoryId,
      typeAsset
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-customer {
  font-size: 0.75rem;
}

.th-list-course {
  .th-link {
    color: var(--vb-color-primary) !important;

    &:hover {
      color: var(--kit-color-primary) !important;
    }
  }
}
</style>
